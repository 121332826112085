import "./TripStyles.css";
import TripData from "./TripData";
import Trip1 from "../assets/path.webp";
import Trip2 from "../assets/admin.png";
import Trip3 from "../assets/masm.webp";
import Trip4 from "../assets/profile.PNG";
import Trip5 from "../assets/dogcodePhoto.PNG";
import Aboutpdf from "../assets/MatrixScript-master.zip";
import Aboutpdf2 from "../assets/AssemblyProject-master.zip";

function Trip() {
  return (
    <div className="trip">
      <h1>Recent Projects</h1>
      <p>You can discover unique Projects at Github
      </p>
      <div className="tripcard">
        <TripData image={Trip1}
        heading=" Matrix Project"
        text="This Bash Script is a command line program. It provides the user with matrix math functions. The program can read data from files inorder to carryout the matrix operations. "
        url={Aboutpdf}
        buttonText="Download"
        btnClass="show"

        />

        <TripData image={Trip2}
                heading="Full Stack Admin Privileges"
                text="A Full-Stack NodeJS app. With a React Front-End. A login and Admin System that allows for user deletion. An API is used to store images. So users can register and choose a photo to save in our database"
                url="https://638ed2c464c021726f29c5e6--symphonious-babka-6175a8.netlify.app/"
                buttonText="Demo"
                btnClass="show"
                />

        <TripData image={Trip3}
                heading="Assembly Mathematics"
                text="This ASM program is a basic calculator. It shows the sum, product, quotient and remainder of two numbers. Open the repository to see the memory registers utilized to carry out the operations. "
                url={Aboutpdf2}
                buttonText="Download"
                btnClass="show"
                />
      </div>
      <div className="tripcard">
      <div width="15%"></div>
      <TripData image={Trip4}
        heading=" Full Stack Admin with Profiles"
        text="This program allows users to login and create a profile using Google Oath. Their profile is designed using the metadata from their Gmail. Additionally, users can purchase credits using their credit/debit card "
        url="https://obscure-harbor-36388-f240c37a941c.herokuapp.com/"
        buttonText="Demo"
        btnClass="show"

        />
        <TripData image={Trip5}
        heading=" Custom React Website"
        text="This project was for a client that runs a doggy daycare business. The design was tailored to the clients unique brand and style. Working with the client to make their vison come alive was a valuable experience. "
        url="https://www.dogcodeboston.com/"
        buttonText="Demo"
        btnClass="show"

        />
        <div width="15%"></div>
        
      </div>
    </div>
  );
}

export default Trip;