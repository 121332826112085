import React, { useState } from 'react';
import './ProfileStyles.css';
import { MDBCol, MDBContainer, MDBRow, MDBCard, MDBCardText, MDBCardBody, MDBCardImage, MDBTypography } from 'mdb-react-ui-kit';
import photo from '../assets/headshot.jpg';
import ZoomPhoto from './ZoomPhoto';

export default function EditButton() {
  const [theme, setTheme] = useState('light'); // State to track theme

  // Function to toggle between light and dark themes
  const toggleTheme = () => {
    setTheme(theme === 'light' ? 'dark' : 'light');
  };

  return (
    <div className={`profile-page ${theme}`}>
      <MDBContainer className="py-5 h-100">
        <MDBRow className="justify-content-center align-items-center h-100">
          <MDBCol lg="9" xl="7">
            <MDBCard className="profile-card">
              <div className="rounded-top text-white d-flex flex-row profile-header">
                <div className="ms-4 mt-5 d-flex flex-column profile-image">
                  <MDBCardImage src={photo} alt="Profile" className="mt-4 mb-2 img-thumbnail" fluid style={{ width: '150px', zIndex: '1' }} />
                </div>
                <div className="ms-3 profile-details">
                  <MDBTypography tag="h5">Danny Gonzalez</MDBTypography>
                  <MDBCardText>Boston, MA</MDBCardText>
                </div>
              </div>
              <div className="p-4 profile-stats">
                <div className="d-flex justify-content-end text-center py-1">
                  <div>
                    <MDBCardText className="mb-1 h5">253</MDBCardText>
                    <MDBCardText className="small text-muted mb-0">Photos</MDBCardText>
                  </div>
                  <div className="px-3">
                    <MDBCardText className="mb-1 h5">6</MDBCardText>
                    <MDBCardText className="small text-muted mb-0">Yrs Exp</MDBCardText>
                  </div>
                  <div>
                    <MDBCardText className="mb-1 h5">4</MDBCardText>
                    <MDBCardText className="small text-muted mb-0">Companies</MDBCardText>
                  </div>
                </div>
              </div>
              <MDBCardBody className="text-black p-4 profile-content">
                <div className="mb-5">
                  <h2 className="heading">Granite Telecommunications MS Representative</h2>
                  <div className="p-4 profile-experience">
                    <ul className="list pl-0">
                      <li className="item mb-4">
                        Configured and maintained Cisco routers and switches, including VLANs, LTE, and routing protocols.
                      </li>
                      <li className="item mb-4">
                        Designed and implemented network topologies using tools such as CiscoVmanage.
                      </li>
                      <li className="item mb-4">
                        Deployed and managed firewalls, including Cisco ASA and Fortinet FortiGate, to secure network traffic and prevent unauthorized access.
                      </li>
                      <li className="item mb-4">
                        Monitored network performance and diagnosed issues using tools such as Wireshark and SolarWinds.
                      </li>
                      <li className="item mb-4">
                        Designed and deployed wireless networks using standards such as 802.11ac and 802.11n.
                      </li>
                      <li className="item mb-4">
                        Implemented network security measures such as VPNs and SSL encryption to secure remote access and web traffic.
                      </li>
                      <li className="item mb-4">
                        Configured and maintained DNS and DHCP servers to manage network traffic and provide IP addresses to clients.
                      </li>
                      <li className="item mb-4">
                        Managed Active Directory and LDAP authentication services to control network access and user permissions.
                      </li>
                      <li className="item">
                        Developed and implemented disaster recovery plans to ensure network availability in case of system failures or disasters.
                      </li>
                    </ul>
                  </div>
                </div>
                <ZoomPhoto />
              </MDBCardBody>
            </MDBCard>
          </MDBCol>
        </MDBRow>
      </MDBContainer>
    </div>
  );
}
